<template>
  <div class="order__card">
    <span class="order__card__status">{{order.status.translations.find(({ locale }) => locale === $i18n.locale).name}}</span>
    <div class="order__card__slider">
      <div
        class="order__card__slider__arrow order__card__slider__arrow_left"
        v-show="order.goods.length > 1"
        @click="prevProduct(order.goods.length)"
      />
      <div
        class="order__card__slider__arrow order__card__slider__arrow_right"
        v-show="order.goods.length > 1"
        @click="nextProduct(order.goods.length)"
      />
      <carousel
        navigationNextLabel="next"
        paginationActiveColor="#6F4189"
        paginationColor="white"
        :per-page="1"
        :navigateTo="manualNavigation"
        :paginationEnabled=false
      >
        <slide v-for="slide in order.goods" :key="slide.id">
          <div class="order__card__slider__item">
            <img
              :src="getImageUrl(slide.gallery.find(({ is_main }) => !!is_main)?.image)"
              class="order__card__slider__item__img"
              :alt="slide.translations.find(({ locale }) => locale === $i18n.locale).name"
            />
            <div class="order__card__slider__item__text">{{ slide.translations.find(({ locale }) => locale === $i18n.locale).name }}</div>
          </div>
          <router-link
            :to="{
              name: 'goods-details',
              params: { id: slide.id }
            }"
            class="order__card__slider__item__view"
          >
            Посмотреть товар
          </router-link>
        </slide>
      </carousel>
    </div>
    <div class="order__card__info">
      <div class="order__card__info__number">
        Заказ № {{ order.id }}
      </div>
      <div v-if="false" class="order__card__info__track">
        <span class="order__card__info__track__label">
          Номер отслеживания
        </span>
        <a href="#" class="order__card__info__track__number">
          IW3475453455
        </a>
      </div>

      <div class="order__card__info__goods">
        <div class="order__card__info__price">
          <div class="order__card__info__goods__label">Стоимость предметов</div>
          <div class="order__card__info__goods__number">{{ getGoodsTotalPrice(order.goods) }}</div>
        </div>

        <div class="order__card__info__quantity">
          <div class="order__card__info__goods__label">Количество</div>
          <div class="order__card__info__goods__number">{{ getGoodsCount(order.goods) }} позиций</div>
        </div>
      </div>

      <div class="order__card__info__delivery">
        Доставка
      </div>
      <div class="order__card__info__delivery__info">
        <div class="order__card__info__delivery__info__status">Готов к доставке</div>
<!--        <div class="order__card__info__delivery__info__date">- 3 дня доставки</div>-->
      </div>
      <div class="order__card__info__delivery__bar">
        <div class="order__card__info__delivery__bar__part done"/>
        <div class="order__card__info__delivery__bar__part done"/>
        <div class="order__card__info__delivery__bar__part done"/>
        <div class="order__card__info__delivery__bar__part"/>
      </div>
      <div class="order__card__info__purchase__date">Дата покупки: {{ order.updated_at | formatDate }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "OrderCard",
    props: {
      order: {
        required: true,
        type: Object,
      }
    },
    data() {
      return {
        manualNavigation: 0,
        noPhoto: require('@/assets/images/no_photo.png')
      };
    },
    methods: {
      getImageUrl(fileName) {
        return fileName ? `${API_ROOT}/images/goods/${fileName}` : this.noPhoto;
      },
      getGoodsCount(goods) {
        let countGoods = 0;

        for (let i = 0; i < goods.length; i++) {
          countGoods += goods[i].pivot.count;
        }

        return countGoods;
      },
      getGoodsTotalPrice(goods) {
        let price = 0;

        for (let i = 0; i < goods.length; i++) {
          price += goods[i].pivot.count * goods[i].price;
        }

        return price;
      },
      nextProduct(count) {
        this.manualNavigation = this.manualNavigation >= count - 1 ? 0 : this.manualNavigation + 1;
      },
      prevProduct(count) {
        this.manualNavigation = this.manualNavigation <= 0 ? count - 1 : this.manualNavigation - 1;
      },
    },
  }
</script>

<style scoped>

</style>
