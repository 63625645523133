<template>
  <div class="my_orders_block">
    <div class="my_orders_header_block">
      <div class="my_orders_header_container">
        <div class="my_courses_header_label">
          {{$t('My_orders')}}
        </div>
      </div>
    </div>
    <div class="my_orders_main_block">
      <div class="my_orders_main_container">
        <div class="order__list">
          <OrderCard v-for="order in orderList" :order="order" :key="order.id" />
        </div>
        <div class="my_orders_pagination">
          <MyPagination
            :totalPages="Math.ceil(total / perPage)"
            :total="total"
            :perPage="perPage"
            :currentPage="currentPage"
            @pagechanged="onPageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MyPagination from "@/components/MyPagination";
  import OrderCard from "./components/OrderCard";

  export default {
    name: "MyOrders",
    components: {MyPagination, OrderCard},
    data() {
      return {
        currentPage: 1,
        total: 0,
        perPage: 9,
        orderList: [],
      }
    },
    methods: {
      userOrders() {
        this.$http.get(API_ROOT + `/api/shop/orders`)
          .then(({ data }) => {
            this.orderList = data.data;
            this.currentPage = data.current_page;
            this.total = data.total;
            this.perPage = data.per_page;
          })
          .catch(reason => console.error(reason));
      },
      onPageChange(page) {
        this.$http.get(`${API_ROOT}/api/shop/orders`, {params: { page }})
          .then(({ data }) => {
            this.orderList = data.data;
            this.currentPage = data.current_page;
            this.total = data.total;
          })
          .catch(reason => console.error(reason));
      },
    },
    mounted() {
      this.userOrders()
    }
  }
</script>
